import React, { useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Grid, IconButton, Snackbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import Header from '../components/Header'
import InputField from '../components/InputField'
import { StyledButton } from '../components/Button'
import { env } from '../env'
import { colours } from '../utils/colours'
import { sendRequest } from '../utils/services'
import {
  UPDATE_MODE,
  UPDATE_USER,
  UPDATE_AUTH,
  UPDATE_ERROR,
  UPDATE_AVAILABLE_CHAMPIONSHIPS,
  UPDATE_AVAILABLE_CHAMPIONSHIP_NAMES,
} from '../container/global/types'

const BACKEND = `${env.api.endpoint.protocol}://${env.api.endpoint.host}:${env.api.endpoint.port}/api`
const MinWidth = 600

const Home = ({ user, error }) => {
  const [password, setPassword] = useState()

  const history = useHistory()

  const dispatch = useDispatch()

  const errorHandler = () => dispatch({ type: UPDATE_ERROR })

  // authentication
  const authenticate = async (userID, password, mode, path) => {
    try {
      const authData = await sendRequest(
        `${BACKEND}/auth/login`,
        'POST',
        JSON.stringify({ userID, password }),
        { 'Content-Type': 'application/json' }
      )

      if (!authData || !authData.user) {
        dispatch({ type: UPDATE_ERROR, payload: 'Autenticazione fallita.' })
        dispatch({ type: UPDATE_AUTH, payload: false })
        dispatch({ type: UPDATE_USER })
        dispatch({ type: UPDATE_MODE })
        setPassword()
      } else {
        dispatch({ type: UPDATE_AUTH, payload: true })
        dispatch({ type: UPDATE_USER, payload: authData.user.userID })
        dispatch({ type: UPDATE_MODE, payload: mode })
        dispatch({
          type: UPDATE_AVAILABLE_CHAMPIONSHIPS,
          payload: authData.user.championships,
        })
        dispatch({
          type: UPDATE_AVAILABLE_CHAMPIONSHIP_NAMES,
          payload: authData.user.championshipNames,
        })
        history.push(path)
      }
    } catch (err) {
      console.log(err)
      setPassword()
      dispatch({ type: UPDATE_ERROR, payload: 'Credenziali errate.' })
      dispatch({ type: UPDATE_AUTH, payload: false })
      dispatch({ type: UPDATE_USER })
      dispatch({ type: UPDATE_MODE })
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!error}
        onClose={errorHandler}
        message={error}
        action={
          <>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={errorHandler}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
      <Header MinWidth={MinWidth} />
      &nbsp;
      <Grid
        container
        item
        alignItems='center'
        spacing={3}
        style={{ minWidth: MinWidth }}
      >
        <Grid
          container
          item
          xs
          alignItems='center'
          direction='column'
          spacing={3}
        >
          <Grid item xs>
            <h3>Login</h3>
          </Grid>
          <Grid container item xs alignItems='center'>
            <Grid item xs md lg />
            <Grid item xs={8} md={6} lg={4}>
              <InputField
                id='user'
                value={user}
                dispatchAction={true}
                dispatchType={UPDATE_USER}
                label='Nome utente'
                errorText='Nome utente troppo corto!'
                errorFunc={user =>
                  user && user.trim().split(' ').join('').length < 4
                }
                type='text'
              />
            </Grid>
            <Grid item xs md lg />
          </Grid>
          <Grid container item xs alignItems='center'>
            <Grid item xs md lg />
            <Grid item xs={8} md={6} lg={4}>
              <InputField
                id='password'
                value={password}
                onChange={setPassword}
                label='Password'
                errorText='Password troppo corta!'
                errorFunc={user =>
                  user && user.trim().split(' ').join('').length < 4
                }
                type='password'
              />
            </Grid>
            <Grid item xs md lg />
          </Grid>
          <Grid item xs>
            <center>
              <StyledButton
                buttonwidth={100}
                buttonheight={50}
                backgroundcolour={colours.mainLight}
                hovercolour={colours.main}
                disabled={!user || !password}
                onClick={() =>
                  authenticate(user, password, 'analysis', '/dashboard')
                }
              >
                Vai
              </StyledButton>
            </center>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs
          alignItems='center'
          direction='column'
          spacing={3}
        >
          <Grid item xs>
            <h3>Prova la versione DEMO</h3>
          </Grid>
          <Grid item xs>
            <center>
              <Link
                to='/simulation'
                style={{ textDecoration: 'none' }}
                onClick={() =>
                  authenticate('demo', 'demo', 'demo', '/simulation')
                }
              >
                <StyledButton
                  buttonwidth={100}
                  buttonheight={50}
                  backgroundcolour={colours.mainLight}
                  hovercolour={colours.main}
                >
                  Vai
                </StyledButton>
              </Link>
            </center>
          </Grid>
        </Grid>
      </Grid>
      &nbsp;
    </>
  )
}

const mapStateToProps = state => ({
  user: state.global.user,
  error: state.global.error,
})

const ConnectedHome = connect(mapStateToProps)(Home)

export default ConnectedHome
