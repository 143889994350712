import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Tooltip,
} from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import CloseIcon from '@material-ui/icons/Close'
import ClearIcon from '@material-ui/icons/Clear'
import CasinoIcon from '@material-ui/icons/Casino'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'

import Bonus from '../components/Bonus'
import Panel from '../components/Panel'
import Header from '../components/Header'
import Tactic from '../components/Tactic'
import SubsLog from '../components/SubsLog'
import Rotations from '../components/Rotations'
import Tabellino from '../components/Tabellino'
import InputField from '../components/InputField'
import Substitution from '../components/Substitution'
import LineupSelection from '../components/LineupSelection'
import DrawingSelection from '../components/DrawingSelection'
import { StyledButton } from '../components/Button'
import { env } from '../env'
import { colours } from '../utils/colours'
import { sendRequest } from '../utils/services'
import { useInterval } from '../hooks/loop-hook'
import {
  RESET_GLOBAL,
  UPDATE_ERROR,
  UPDATE_DEFAULT_NX,
  UPDATE_SELECTED_CHAMPIONSHIP,
} from '../container/global/types'
import { RESET_DASHBOARD } from '../container/dashboard/types'
import {
  RESET_SIMULATION,
  UPDATE_SIMULATIONID,
  UPDATE_STATUS,
  UPDATE_DIVIDE_BY_ROLE,
  UPDATE_IS_PLAYABLE,
  UPDATE_RISULTATO,
  UPDATE_PARZIALI,
  UPDATE_SUBS_LOG,
  UPDATE_TABELLINO,
  UPDATE_SERVES_A,
  UPDATE_TEAM_A_BONUS,
  UPDATE_TEAM_B_BONUS,
  UPDATE_TEAM_A_TACTICS_VARIABLES,
  UPDATE_TEAM_A_TACTICS_VALUES,
  UPDATE_TEAM_B_TACTICS_VARIABLES,
  UPDATE_TEAM_B_TACTICS_VALUES,
  RESET_TEAM_A_SUBS_SERVICE,
  RESET_TEAM_B_SUBS_SERVICE,
  RESET_TEAM_A_SUBS_DIAGONAL,
  RESET_TEAM_B_SUBS_DIAGONAL,
  RESET_RESULTS,
  RESET_ROTATIONS,
  RESET_SUBSTITUTIONS,
  RESET_BONUS,
  RESET_TACTICS,
} from '../container/simulation/types'
import {
  UPDATE_AVAILABLE_PLAYERS,
  UPDATE_AVAILABLE_SETTERS,
  UPDATE_AVAILABLE_OPPOSITES,
  UPDATE_AVAILABLE_MIDDLES,
  UPDATE_AVAILABLE_HITTERS,
  UPDATE_AVAILABLE_LIBEROS,
  UPDATE_SELECTED_SETTER_A,
  UPDATE_SELECTED_OPPOSITE_A,
  UPDATE_SELECTED_MIDDLE1_A,
  UPDATE_SELECTED_MIDDLE2_A,
  UPDATE_SELECTED_HITTER1_A,
  UPDATE_SELECTED_HITTER2_A,
  UPDATE_SELECTED_LIBERO_A,
  UPDATE_SELECTED_SETTER_B,
  UPDATE_SELECTED_OPPOSITE_B,
  UPDATE_SELECTED_MIDDLE1_B,
  UPDATE_SELECTED_MIDDLE2_B,
  UPDATE_SELECTED_HITTER1_B,
  UPDATE_SELECTED_HITTER2_B,
  UPDATE_SELECTED_LIBERO_B,
  UPDATE_AVAILABLE_TEAMS,
  UPDATE_SELECTED_TEAM_A,
  UPDATE_SELECTED_TEAM_B,
  RESET_PLAYERS,
} from '../container/players/types'

const BACKEND = `${env.api.endpoint.protocol}://${env.api.endpoint.host}:${env.api.endpoint.port}/api`

const MinWidth = 1100

const Simulation = ({
  user,
  error,
  mode,
  defaultNx,
  simulationID,
  description,
  availableChampionships,
  availableChampionshipNames,
  selectedChampionship,
  status,
  Nx,
  divideByRole,
  isPlayable,
  risultato,
  parziali,
  tabellino,
  subsLog,
  servesA,
  teamArotations,
  teamBrotations,
  teamAbonus,
  teamBbonus,
  teamAtacticsVariables,
  teamAtacticsValues,
  teamBtacticsVariables,
  teamBtacticsValues,
  teamAsubsService,
  teamBsubsService,
  teamAsubsDiagonal,
  teamBsubsDiagonal,
  availablePlayers,
  availableSetters,
  availableOpposites,
  availableMiddles,
  availableHitters,
  availableLiberos,
  selectedSetterA,
  selectedOppositeA,
  selectedMiddle1A,
  selectedMiddle2A,
  selectedHitter1A,
  selectedHitter2A,
  selectedLiberoA,
  selectedSetterB,
  selectedOppositeB,
  selectedMiddle1B,
  selectedMiddle2B,
  selectedHitter1B,
  selectedHitter2B,
  selectedLiberoB,
  availableTeams,
  selectedTeamA,
  selectedTeamB,
}) => {
  const dispatch = useDispatch()

  // state to manage the linear progress
  const [progress, setProgress] = useState(0)

  // state to manage the simulation outputs polling
  const [simulationOutputsDone, setSimulationOutputsDone] = useState(false)

  // states to manage the panel boxes
  const [boxSub1A, setBoxSub1A] = useState(false)
  const [boxSub2A, setBoxSub2A] = useState(false)
  const [boxSub1B, setBoxSub1B] = useState(false)
  const [boxSub2B, setBoxSub2B] = useState(false)
  const [boxRot1A, setBoxRot1A] = useState(false)
  const [boxRot2A, setBoxRot2A] = useState(false)
  const [boxRot1B, setBoxRot1B] = useState(false)
  const [boxRot2B, setBoxRot2B] = useState(false)
  const [boxTec1A, setBoxTec1A] = useState(false)
  const [boxTec2A, setBoxTec2A] = useState(false)
  const [boxTec1B, setBoxTec1B] = useState(false)
  const [boxTec2B, setBoxTec2B] = useState(false)
  const [boxTac1A, setBoxTac1A] = useState(false)
  const [boxTac2A, setBoxTac2A] = useState(false)
  const [boxTac1B, setBoxTac1B] = useState(false)
  const [boxTac2B, setBoxTac2B] = useState(false)
  const [boxCha1A, setBoxCha1A] = useState(false)
  const [boxCha2A, setBoxCha2A] = useState(false)
  const [boxCha1B, setBoxCha1B] = useState(false)
  const [boxCha2B, setBoxCha2B] = useState(false)

  // state to manage the dialogs opening
  const [openRot, setOpenRot] = useState(false)
  const [openTec, setOpenTec] = useState(false)
  const [openTac, setOpenTac] = useState(false)
  const [openCha, setOpenCha] = useState(false)
  const [openTab, setOpenTab] = useState(false)
  const [openLog, setOpenLog] = useState(false)

  // set selected championship
  useEffect(() => {
    if (
      !selectedChampionship &&
      !!availableChampionships &&
      availableChampionships.length > 0
    )
      dispatch({
        type: UPDATE_SELECTED_CHAMPIONSHIP,
        payload: availableChampionships[0],
      })
  }, [availableChampionships, dispatch, selectedChampionship])

  // fetch default Nx
  useEffect(() => {
    const fetchDefaultNx = async () => {
      try {
        const defaultNxData = await sendRequest(
          `${BACKEND}/backend-R/defaultNx`
        )

        if (!defaultNxData || !defaultNxData.defaultNx) return

        dispatch({ type: UPDATE_DEFAULT_NX, payload: defaultNxData.defaultNx })
      } catch (err) {
        console.log(err)
      }
    }
    fetchDefaultNx()
  }, [dispatch])

  // fetch players
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const playersData = await sendRequest(
          `${BACKEND}/players/${selectedChampionship}`,
          'GET',
          null,
          { Authorization: user }
        )

        if (!playersData) {
          dispatch({ type: UPDATE_SELECTED_TEAM_A })
          dispatch({ type: UPDATE_SELECTED_TEAM_B })
          dispatch({ type: UPDATE_AVAILABLE_TEAMS, payload: [] })
          dispatch({
            type: UPDATE_AVAILABLE_PLAYERS,
            payload: { championship: selectedChampionship, players: [] },
          })
          return
        }

        dispatch({
          type: UPDATE_AVAILABLE_TEAMS,
          payload: playersData
            .map(p => p.team)
            .filter((x, i, a) => a.indexOf(x) === i)
            .sort(),
        })
        dispatch({
          type: UPDATE_AVAILABLE_PLAYERS,
          payload: {
            championship: selectedChampionship,
            players: playersData.sort((a, b) =>
              a.surname > b.surname ? 1 : -1
            ),
          },
        })
      } catch (err) {
        console.log(err)
        dispatch({ type: UPDATE_SELECTED_TEAM_A })
        dispatch({ type: UPDATE_SELECTED_TEAM_B })
        dispatch({ type: UPDATE_AVAILABLE_TEAMS, payload: [] })
        dispatch({
          type: UPDATE_AVAILABLE_PLAYERS,
          payload: { championship: selectedChampionship, players: [] },
        })
      }
    }
    fetchPlayers()
  }, [dispatch, selectedChampionship, user])

  // fetch setters
  useEffect(() => {
    const fetchSetters = async () => {
      try {
        const settersData = await sendRequest(
          `${BACKEND}/players/${selectedChampionship}/role/Palleggiatore`,
          'GET',
          null,
          { Authorization: user }
        )

        if (!settersData) return

        dispatch({
          type: UPDATE_AVAILABLE_SETTERS,
          payload: {
            championship: selectedChampionship,
            players: settersData.sort((a, b) =>
              a.surname > b.surname ? 1 : -1
            ),
          },
        })
      } catch (err) {
        console.log(err)
      }
    }
    fetchSetters()
  }, [dispatch, selectedChampionship, user])

  // fetch opposites
  useEffect(() => {
    const fetchOpposites = async () => {
      try {
        const oppositesData = await sendRequest(
          `${BACKEND}/players/${selectedChampionship}/role/Opposto`,
          'GET',
          null,
          { Authorization: user }
        )

        if (!oppositesData) return

        dispatch({
          type: UPDATE_AVAILABLE_OPPOSITES,
          payload: {
            championship: selectedChampionship,
            players: oppositesData.sort((a, b) =>
              a.surname > b.surname ? 1 : -1
            ),
          },
        })
      } catch (err) {
        console.log(err)
      }
    }
    fetchOpposites()
  }, [dispatch, selectedChampionship, user])

  // fetch middles
  useEffect(() => {
    const fetchMiddles = async () => {
      try {
        const middlesData = await sendRequest(
          `${BACKEND}/players/${selectedChampionship}/role/Centro`,
          'GET',
          null,
          { Authorization: user }
        )

        if (!middlesData) return

        dispatch({
          type: UPDATE_AVAILABLE_MIDDLES,
          payload: {
            championship: selectedChampionship,
            players: middlesData.sort((a, b) =>
              a.surname > b.surname ? 1 : -1
            ),
          },
        })
      } catch (err) {
        console.log(err)
      }
    }
    fetchMiddles()
  }, [dispatch, selectedChampionship, user])

  // fetch hitters
  useEffect(() => {
    const fetchHitters = async () => {
      try {
        const hittersData = await sendRequest(
          `${BACKEND}/players/${selectedChampionship}/role/Schiacciatore`,
          'GET',
          null,
          { Authorization: user }
        )

        if (!hittersData) return

        dispatch({
          type: UPDATE_AVAILABLE_HITTERS,
          payload: {
            championship: selectedChampionship,
            players: hittersData.sort((a, b) =>
              a.surname > b.surname ? 1 : -1
            ),
          },
        })
      } catch (err) {
        console.log(err)
      }
    }
    fetchHitters()
  }, [dispatch, selectedChampionship, user])

  // fetch liberos
  useEffect(() => {
    const fetchLiberos = async () => {
      try {
        const liberosData = await sendRequest(
          `${BACKEND}/players/${selectedChampionship}/role/Libero`,
          'GET',
          null,
          { Authorization: user }
        )

        if (!liberosData) return

        dispatch({
          type: UPDATE_AVAILABLE_LIBEROS,
          payload: {
            championship: selectedChampionship,
            players: liberosData.sort((a, b) =>
              a.surname > b.surname ? 1 : -1
            ),
          },
        })
      } catch (err) {
        console.log(err)
      }
    }
    fetchLiberos()
  }, [dispatch, selectedChampionship, user])

  useEffect(() => {
    dispatch({
      type: UPDATE_IS_PLAYABLE,
      payload:
        selectedSetterA &&
        selectedOppositeA &&
        selectedMiddle1A &&
        selectedMiddle2A &&
        selectedHitter1A &&
        selectedHitter2A &&
        selectedLiberoA &&
        selectedSetterB &&
        selectedOppositeB &&
        selectedMiddle1B &&
        selectedMiddle2B &&
        selectedHitter1B &&
        selectedHitter2B &&
        selectedLiberoB &&
        teamArotations?.length === 5 &&
        teamBrotations?.length === 5 &&
        +defaultNx > 0,
    })
  }, [
    defaultNx,
    dispatch,
    selectedHitter1A,
    selectedHitter1B,
    selectedHitter2A,
    selectedHitter2B,
    selectedLiberoA,
    selectedLiberoB,
    selectedMiddle1A,
    selectedMiddle1B,
    selectedMiddle2A,
    selectedMiddle2B,
    selectedOppositeA,
    selectedOppositeB,
    selectedSetterA,
    selectedSetterB,
    teamArotations,
    teamBrotations,
  ])

  const SimulateMatch = async (
    Nx = 1,
    descriptions = undefined,
    defaultService = undefined,
    RotsAs = undefined,
    RotsBs = undefined
  ) => {
    dispatch({ type: RESET_RESULTS })
    setProgress(0)

    const simulationIDtemp = `${Date.now()}${Math.round(
      Math.random() * 1000000
    )}`
    dispatch({ type: UPDATE_SIMULATIONID, payload: simulationIDtemp })
    dispatch({ type: UPDATE_STATUS, payload: 'waiting' })

    try {
      await sendRequest(
        `${BACKEND}/simulation-input/add`,
        'POST',
        JSON.stringify({
          simulationID: simulationIDtemp,
          userID: user,
          Nx: Nx,
          Championship: selectedChampionship,
          teamAname: selectedTeamA,
          teamBname: selectedTeamB,
          IDteamA:
            selectedSetterA.playerID +
            '-' +
            selectedOppositeA.playerID +
            '-' +
            selectedMiddle1A.playerID +
            '-' +
            selectedMiddle2A.playerID +
            '-' +
            selectedHitter1A.playerID +
            '-' +
            selectedHitter2A.playerID +
            '-' +
            selectedLiberoA.playerID,
          IDteamB:
            selectedSetterB.playerID +
            '-' +
            selectedOppositeB.playerID +
            '-' +
            selectedMiddle1B.playerID +
            '-' +
            selectedMiddle2B.playerID +
            '-' +
            selectedHitter1B.playerID +
            '-' +
            selectedHitter2B.playerID +
            '-' +
            selectedLiberoB.playerID,
          ServesA:
            defaultService === true
              ? '1'
              : defaultService === false
              ? '0'
              : servesA === true
              ? '1'
              : '0',
          RotsA: (!!RotsAs && RotsAs) || teamArotations.join('-'),
          RotsB: (!!RotsBs && RotsBs) || teamBrotations.join('-'),
          BonusA: teamAbonus,
          BonusB: teamBbonus,
          TactA: teamAtacticsVariables + '-' + teamAtacticsValues,
          TactB: teamBtacticsVariables + '-' + teamBtacticsValues,
          SubAser:
            ((!!teamAsubsService.out && teamAsubsService.out.playerID) || 0) +
            '-' +
            ((!!teamAsubsService.in && teamAsubsService.in.playerID) || 0) +
            '-' +
            (teamAsubsService.when || 0),
          SubBser:
            ((!!teamBsubsService.out && teamBsubsService.out.playerID) || 0) +
            '-' +
            ((!!teamBsubsService.in && teamBsubsService.in.playerID) || 0) +
            '-' +
            (teamBsubsService.when || 0),
          SubAdia:
            ((!!teamAsubsDiagonal.out1 && teamAsubsDiagonal.out1.playerID) ||
              0) +
            '-' +
            ((!!teamAsubsDiagonal.out2 && teamAsubsDiagonal.out2.playerID) ||
              0) +
            '-' +
            ((!!teamAsubsDiagonal.in1 && teamAsubsDiagonal.in1.playerID) || 0) +
            '-' +
            ((!!teamAsubsDiagonal.in2 && teamAsubsDiagonal.in2.playerID) || 0) +
            '-' +
            (teamAsubsDiagonal.when || 0),
          SubBdia:
            ((!!teamBsubsDiagonal.out1 && teamBsubsDiagonal.out1.playerID) ||
              0) +
            '-' +
            ((!!teamBsubsDiagonal.out2 && teamBsubsDiagonal.out2.playerID) ||
              0) +
            '-' +
            ((!!teamBsubsDiagonal.in1 && teamBsubsDiagonal.in1.playerID) || 0) +
            '-' +
            ((!!teamBsubsDiagonal.in2 && teamBsubsDiagonal.in2.playerID) || 0) +
            '-' +
            (teamBsubsDiagonal.when || 0),
          description:
            (mode === 'demo' && 'Versione DEMO') ||
            (!!descriptions && descriptions) ||
            '',
        }),
        { 'Content-Type': 'application/json', Authorization: user }
      )
    } catch (err) {
      console.log(err)
      dispatch({ type: UPDATE_STATUS, payload: 'error' })
    }
  }

  // const SimulateSixOffsets = (Nx = 1) => {
  //   dispatch({ type: UPDATE_SIMULATIONID, payload: undefined });
  //   SimulateMatch(Nx, "Offset 0", true, "1-2-1-2-1", "2-2-2-2-2");
  //   SimulateMatch(Nx, "Offset 1", true, "1-2-1-2-1", "1-1-1-1-1");
  //   SimulateMatch(Nx, "Offset 2", true, "1-2-1-2-1", "6-6-6-6-6");
  //   SimulateMatch(Nx, "Offset 3", true, "1-2-1-2-1", "5-5-5-5-5");
  //   SimulateMatch(Nx, "Offset 4", true, "1-2-1-2-1", "4-4-4-4-4");
  //   SimulateMatch(Nx, "Offset 5", true, "1-2-1-2-1", "3-3-3-3-3");
  // };

  const deleteSimulation = async () => {
    if (!!simulationID) {
      try {
        await sendRequest(
          `${BACKEND}/simulation/delete/${simulationID}`,
          'DELETE',
          null,
          { Authorization: user }
        )
      } catch (err) {
        console.log(err)
      }
      Reset()
    }
  }

  const checkSimulationOutputs = async () => {
    if (simulationOutputsDone || !simulationID) return

    if (status === 'new') {
      setSimulationOutputsDone(true)
      return
    }

    let simulationOutput
    try {
      simulationOutput = await sendRequest(
        `${BACKEND}/simulation-output/${simulationID}`,
        'GET',
        null,
        { Authorization: user }
      )
    } catch (err) {
      console.log(err)
    }

    if (!simulationOutput?.data?.status) return

    simulationOutput = simulationOutput.data

    dispatch({ type: UPDATE_STATUS, payload: simulationOutput.status })

    if (
      simulationOutput.status === 'running' ||
      simulationOutput.status === 'waiting'
    )
      return

    if (simulationOutput.status === 'error') {
      dispatch({ type: UPDATE_RISULTATO, payload: 'Errore' })
      dispatch({ type: UPDATE_PARZIALI, payload: [] })
      dispatch({ type: UPDATE_TABELLINO })
      dispatch({ type: UPDATE_SUBS_LOG })
    } else {
      if (simulationOutput.Nx === 1) {
        dispatch({
          type: UPDATE_RISULTATO,
          payload:
            simulationOutput.Score.SetsA !== undefined &&
            simulationOutput.Score.SetsB !== undefined
              ? `${simulationOutput.Score.SetsA} - ${simulationOutput.Score.SetsB}`
              : 'Errore',
        })
        let simulationParziali = []
        for (let i = 0; i < simulationOutput.Score.PointsA.length; i += 1) {
          simulationParziali.push(
            `${simulationOutput.Score.PointsA[i]} - ${simulationOutput.Score.PointsB[i]}`
          )
        }
        dispatch({ type: UPDATE_PARZIALI, payload: simulationParziali })
      } else {
        dispatch({
          type: UPDATE_RISULTATO,
          payload:
            simulationOutput.Apoints !== undefined &&
            simulationOutput.Bpoints !== undefined
              ? `Punteggio medio ${simulationOutput.Apoints} - ${simulationOutput.Bpoints}`
              : 'Errore',
        })
        dispatch({
          type: UPDATE_PARZIALI,
          payload:
            simulationOutput.Awins !== undefined &&
            simulationOutput.Bwins !== undefined
              ? [
                  `${simulationOutput.Awins}% di vittoria per ${
                    selectedTeamA || 'la squadra A'
                  }`,
                ]
              : [],
        })
      }
      dispatch({ type: UPDATE_TABELLINO, payload: simulationOutput.Stats })
      dispatch({ type: UPDATE_SUBS_LOG, payload: simulationOutput.SubsLog })
    }
    setSimulationOutputsDone(true)
  }

  const Reset = () => {
    setSimulationOutputsDone(false)
    dispatch({ type: UPDATE_STATUS, payload: 'new' })
    dispatch({ type: UPDATE_SIMULATIONID })
    dispatch({ type: RESET_RESULTS })
    dispatch({ type: RESET_ROTATIONS })
    dispatch({ type: RESET_BONUS })
    dispatch({ type: RESET_TACTICS })
    dispatch({ type: RESET_SUBSTITUTIONS })
    clearSelectionsA()
    clearSelectionsB()
    setProgress(0)
    setBoxSub1A(false)
    setBoxSub2A(false)
    setBoxSub1B(false)
    setBoxSub2B(false)
    setBoxRot1A(false)
    setBoxRot2A(false)
    setBoxRot1B(false)
    setBoxRot2B(false)
    setBoxTec1A(false)
    setBoxTec2A(false)
    setBoxTec1B(false)
    setBoxTec2B(false)
    setBoxTac1A(false)
    setBoxTac2A(false)
    setBoxTac1B(false)
    setBoxTac2B(false)
    setBoxCha1A(false)
    setBoxCha2A(false)
    setBoxCha1B(false)
    setBoxCha2B(false)
  }

  // role labels
  const autoLabels = ['P', 'O', 'C1', 'C2', 'S1', 'S2', 'L']

  // players lists by role
  const autoOptions = role => {
    if (!divideByRole) return availablePlayers[selectedChampionship]

    switch (role) {
      case 'P':
        return availableSetters[selectedChampionship]
      case 'O':
        return availableOpposites[selectedChampionship]
      case 'C1':
      case 'C2':
        return availableMiddles[selectedChampionship]
      case 'S1':
      case 'S2':
        return availableHitters[selectedChampionship]
      case 'L':
        return availableLiberos[selectedChampionship]
      default:
        return availablePlayers[selectedChampionship]
    }
  }

  // selection functions for team A
  const autoSelectA = (role, p) => {
    switch (role) {
      case 'P':
        dispatch({ type: UPDATE_SELECTED_SETTER_A, payload: p })
        break
      case 'O':
        dispatch({ type: UPDATE_SELECTED_OPPOSITE_A, payload: p })
        break
      case 'C1':
        dispatch({ type: UPDATE_SELECTED_MIDDLE1_A, payload: p })
        break
      case 'C2':
        dispatch({ type: UPDATE_SELECTED_MIDDLE2_A, payload: p })
        break
      case 'S1':
        dispatch({ type: UPDATE_SELECTED_HITTER1_A, payload: p })
        break
      case 'S2':
        dispatch({ type: UPDATE_SELECTED_HITTER2_A, payload: p })
        break
      case 'L':
        dispatch({ type: UPDATE_SELECTED_LIBERO_A, payload: p })
        break
      default:
        console.log('Role', role, 'not found!')
    }
  }

  // selection functions for team B
  const autoSelectB = (role, p) => {
    switch (role) {
      case 'P':
        dispatch({ type: UPDATE_SELECTED_SETTER_B, payload: p })
        break
      case 'O':
        dispatch({ type: UPDATE_SELECTED_OPPOSITE_B, payload: p })
        break
      case 'C1':
        dispatch({ type: UPDATE_SELECTED_MIDDLE1_B, payload: p })
        break
      case 'C2':
        dispatch({ type: UPDATE_SELECTED_MIDDLE2_B, payload: p })
        break
      case 'S1':
        dispatch({ type: UPDATE_SELECTED_HITTER1_B, payload: p })
        break
      case 'S2':
        dispatch({ type: UPDATE_SELECTED_HITTER2_B, payload: p })
        break
      case 'L':
        dispatch({ type: UPDATE_SELECTED_LIBERO_B, payload: p })
        break
      default:
        console.log('Role', role, 'not found!')
    }
  }

  // selected players for team A
  const autoValuesA = role => {
    switch (role) {
      case 'P':
        return selectedSetterA
      case 'O':
        return selectedOppositeA
      case 'C1':
        return selectedMiddle1A
      case 'C2':
        return selectedMiddle2A
      case 'S1':
        return selectedHitter1A
      case 'S2':
        return selectedHitter2A
      case 'L':
        return selectedLiberoA
      default:
        return {}
    }
  }

  // selected players for team B
  const autoValuesB = role => {
    switch (role) {
      case 'P':
        return selectedSetterB
      case 'O':
        return selectedOppositeB
      case 'C1':
        return selectedMiddle1B
      case 'C2':
        return selectedMiddle2B
      case 'S1':
        return selectedHitter1B
      case 'S2':
        return selectedHitter2B
      case 'L':
        return selectedLiberoB
      default:
        return {}
    }
  }

  // lineup selection for team A
  const autoPlayersA = autoLabels.map((l, i) => (
    <Grid item xs key={i}>
      <Autocomplete
        fullWidth
        options={
          (!!autoOptions(l) &&
            autoOptions(l).length > 0 &&
            (!!selectedTeamA
              ? autoOptions(l).filter(p => p.team === selectedTeamA)
              : autoOptions(l))) ||
          []
        }
        getOptionLabel={op => `${op.name} ${op.surname}`}
        getOptionSelected={op => op.playerID}
        value={autoValuesA(l) || null}
        onChange={(_, value) => autoSelectA(l, value)}
        renderInput={params => <TextField {...params} label={l} />}
      />
    </Grid>
  ))

  // lineup selection for team B
  const autoPlayersB = autoLabels.map((l, i) => (
    <Grid item xs key={i}>
      <Autocomplete
        fullWidth
        options={
          (!!autoOptions(l) &&
            autoOptions(l).length > 0 &&
            (!!selectedTeamB
              ? autoOptions(l).filter(p => p.team === selectedTeamB)
              : autoOptions(l))) ||
          []
        }
        value={autoValuesB(l) || null}
        getOptionLabel={op => `${op.name} ${op.surname}`}
        getOptionSelected={op => op.playerID}
        onChange={(_, value) => autoSelectB(l, value)}
        renderInput={params => <TextField {...params} label={l} />}
      />
    </Grid>
  ))

  const divideByRoleHandler = () => {
    dispatch({ type: UPDATE_DIVIDE_BY_ROLE, payload: !divideByRole })
  }

  const clearSelectionsA = () => {
    dispatch({ type: UPDATE_SELECTED_TEAM_A })
    dispatch({ type: UPDATE_SELECTED_SETTER_A })
    dispatch({ type: UPDATE_SELECTED_OPPOSITE_A })
    dispatch({ type: UPDATE_SELECTED_MIDDLE1_A })
    dispatch({ type: UPDATE_SELECTED_MIDDLE2_A })
    dispatch({ type: UPDATE_SELECTED_HITTER1_A })
    dispatch({ type: UPDATE_SELECTED_HITTER2_A })
    dispatch({ type: UPDATE_SELECTED_LIBERO_A })
  }

  const clearSelectionsB = () => {
    dispatch({ type: UPDATE_SELECTED_TEAM_B })
    dispatch({ type: UPDATE_SELECTED_SETTER_B })
    dispatch({ type: UPDATE_SELECTED_OPPOSITE_B })
    dispatch({ type: UPDATE_SELECTED_MIDDLE1_B })
    dispatch({ type: UPDATE_SELECTED_MIDDLE2_B })
    dispatch({ type: UPDATE_SELECTED_HITTER1_B })
    dispatch({ type: UPDATE_SELECTED_HITTER2_B })
    dispatch({ type: UPDATE_SELECTED_LIBERO_B })
  }

  const randomizeA = () => {
    dispatch({ type: RESET_TEAM_A_SUBS_SERVICE })
    dispatch({ type: RESET_TEAM_A_SUBS_DIAGONAL })

    const randomTeam = availablePlayers[selectedChampionship]
      ? selectedTeamA
        ? availablePlayers[selectedChampionship]
            .filter(pl => pl.team === selectedTeamA)
            .map(pl => pl.teamID)
            .find((x, i, a) => a.indexOf(x) === i)
        : availablePlayers[selectedChampionship].map(pl => pl.teamID)[
            Math.floor(
              Math.random() * availablePlayers[selectedChampionship].length
            )
          ]
      : undefined
    if (randomTeam !== undefined) {
      dispatch({
        type: UPDATE_SELECTED_SETTER_A,
        payload:
          availableSetters[selectedChampionship] &&
          availableSetters[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableSetters[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableSetters[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })
      dispatch({
        type: UPDATE_SELECTED_OPPOSITE_A,
        payload:
          availableOpposites[selectedChampionship] &&
          availableOpposites[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableOpposites[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableOpposites[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })
      dispatch({
        type: UPDATE_SELECTED_MIDDLE1_A,
        payload:
          availableMiddles[selectedChampionship] &&
          availableMiddles[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableMiddles[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableMiddles[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })
      dispatch({
        type: UPDATE_SELECTED_MIDDLE2_A,
        payload:
          availableMiddles[selectedChampionship] &&
          availableMiddles[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableMiddles[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableMiddles[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })
      dispatch({
        type: UPDATE_SELECTED_HITTER1_A,
        payload:
          availableHitters[selectedChampionship] &&
          availableHitters[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableHitters[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableHitters[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })
      dispatch({
        type: UPDATE_SELECTED_HITTER2_A,
        payload:
          availableHitters[selectedChampionship] &&
          availableHitters[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableHitters[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableHitters[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })
      dispatch({
        type: UPDATE_SELECTED_LIBERO_A,
        payload:
          availableLiberos[selectedChampionship] &&
          availableLiberos[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableLiberos[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableLiberos[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })

      dispatch({
        type: UPDATE_SELECTED_TEAM_A,
        payload: availablePlayers[selectedChampionship]
          ? availablePlayers[selectedChampionship]
              .filter(pl => pl.teamID === randomTeam)
              .map(pl => pl.team)
              .find((x, i, a) => a.indexOf(x) === i)
          : undefined,
      })
    }
  }

  const randomizeB = () => {
    dispatch({ type: RESET_TEAM_B_SUBS_SERVICE })
    dispatch({ type: RESET_TEAM_B_SUBS_DIAGONAL })

    const randomTeam = availablePlayers[selectedChampionship]
      ? selectedTeamB
        ? availablePlayers[selectedChampionship]
            .filter(pl => pl.team === selectedTeamB)
            .map(pl => pl.teamID)
            .find((x, i, a) => a.indexOf(x) === i)
        : availablePlayers[selectedChampionship].map(pl => pl.teamID)[
            Math.floor(
              Math.random() * availablePlayers[selectedChampionship].length
            )
          ]
      : undefined
    if (randomTeam !== undefined) {
      dispatch({
        type: UPDATE_SELECTED_SETTER_B,
        payload:
          availableSetters[selectedChampionship] &&
          availableSetters[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableSetters[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableSetters[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })
      dispatch({
        type: UPDATE_SELECTED_OPPOSITE_B,
        payload:
          availableOpposites[selectedChampionship] &&
          availableOpposites[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableOpposites[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableOpposites[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })
      dispatch({
        type: UPDATE_SELECTED_MIDDLE1_B,
        payload:
          availableMiddles[selectedChampionship] &&
          availableMiddles[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableMiddles[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableMiddles[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })
      dispatch({
        type: UPDATE_SELECTED_MIDDLE2_B,
        payload:
          availableMiddles[selectedChampionship] &&
          availableMiddles[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableMiddles[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableMiddles[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })
      dispatch({
        type: UPDATE_SELECTED_HITTER1_B,
        payload:
          availableHitters[selectedChampionship] &&
          availableHitters[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableHitters[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableHitters[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })
      dispatch({
        type: UPDATE_SELECTED_HITTER2_B,
        payload:
          availableHitters[selectedChampionship] &&
          availableHitters[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableHitters[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableHitters[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })
      dispatch({
        type: UPDATE_SELECTED_LIBERO_B,
        payload:
          availableLiberos[selectedChampionship] &&
          availableLiberos[selectedChampionship].filter(
            av => av.teamID === randomTeam
          ).length > 0
            ? availableLiberos[selectedChampionship].filter(
                av => av.teamID === randomTeam
              )[
                Math.floor(
                  Math.random() *
                    availableLiberos[selectedChampionship].filter(
                      av => av.teamID === randomTeam
                    ).length
                )
              ]
            : undefined,
      })

      dispatch({
        type: UPDATE_SELECTED_TEAM_B,
        payload: availablePlayers[selectedChampionship]
          ? availablePlayers[selectedChampionship]
              .filter(pl => pl.teamID === randomTeam)
              .map(pl => pl.team)
              .find((x, i, a) => a.indexOf(x) === i)
          : undefined,
      })
    }
  }

  const championshipHandler = i => {
    if (availableChampionships[i] !== selectedChampionship) {
      dispatch({
        type: UPDATE_SELECTED_CHAMPIONSHIP,
        payload: availableChampionships[i],
      })
      dispatch({ type: RESET_PLAYERS })
      dispatch({ type: RESET_SUBSTITUTIONS })
    }
  }

  const handleClickOpenRot = () => setOpenRot(true)
  const handleCloseRot = () => setOpenRot(false)
  const handleClickOpenTec = () => setOpenTec(true)
  const handleCloseTec = () => setOpenTec(false)
  const handleClickOpenTac = () => setOpenTac(true)
  const handleCloseTac = () => setOpenTac(false)
  const handleClickOpenCha = () => setOpenCha(true)

  const handleCloseCha = () => {
    setOpenCha(false)

    // check substitution variables consistency
    if (
      !teamAsubsService.out ||
      !teamAsubsService.in ||
      !teamAsubsService.when
    ) {
      dispatch({ type: RESET_TEAM_A_SUBS_SERVICE })
    }
    if (
      !teamBsubsService.out ||
      !teamBsubsService.in ||
      !teamBsubsService.when
    ) {
      dispatch({ type: RESET_TEAM_B_SUBS_SERVICE })
    }
    if (
      !teamAsubsDiagonal.out1 ||
      !teamAsubsDiagonal.out2 ||
      !teamAsubsDiagonal.in1 ||
      !teamAsubsDiagonal.in2 ||
      !teamAsubsDiagonal.when
    ) {
      dispatch({ type: RESET_TEAM_A_SUBS_DIAGONAL })
    }
    if (
      !teamBsubsDiagonal.out1 ||
      !teamBsubsDiagonal.out2 ||
      !teamBsubsDiagonal.in1 ||
      !teamBsubsDiagonal.in2 ||
      !teamBsubsDiagonal.when
    ) {
      dispatch({ type: RESET_TEAM_B_SUBS_DIAGONAL })
    }
  }

  const handleClickOpenTab = () => setOpenTab(true)
  const handleCloseTab = () => setOpenTab(false)
  const handleClickOpenLog = () => setOpenLog(true)
  const handleCloseLog = () => setOpenLog(false)

  const boxSub1AHandler = () => setBoxSub1A(!boxSub1A)
  const boxSub2AHandler = () => setBoxSub2A(!boxSub2A)
  const boxSub1BHandler = () => setBoxSub1B(!boxSub1B)
  const boxSub2BHandler = () => setBoxSub2B(!boxSub2B)

  const boxRot1AHandler = () => setBoxRot1A(!boxRot1A)
  const boxRot2AHandler = () => setBoxRot2A(!boxRot2A)
  const boxRot1BHandler = () => setBoxRot1B(!boxRot1B)
  const boxRot2BHandler = () => setBoxRot2B(!boxRot2B)

  const boxTec1AHandler = () => setBoxTec1A(!boxTec1A)
  const boxTec2AHandler = () => setBoxTec2A(!boxTec2A)
  const boxTec1BHandler = () => setBoxTec1B(!boxTec1B)
  const boxTec2BHandler = () => setBoxTec2B(!boxTec2B)

  const boxTac1AHandler = () => setBoxTac1A(!boxTac1A)
  const boxTac2AHandler = () => setBoxTac2A(!boxTac2A)
  const boxTac1BHandler = () => setBoxTac1B(!boxTac1B)
  const boxTac2BHandler = () => setBoxTac2B(!boxTac2B)

  const boxCha1AHandler = () => setBoxCha1A(!boxCha1A)
  const boxCha2AHandler = () => setBoxCha2A(!boxCha2A)
  const boxCha1BHandler = () => setBoxCha1B(!boxCha1B)
  const boxCha2BHandler = () => setBoxCha2B(!boxCha2B)

  const servesAHandler = () =>
    dispatch({ type: UPDATE_SERVES_A, payload: !servesA })

  const bonusAHandler = value =>
    dispatch({ type: UPDATE_TEAM_A_BONUS, payload: value })

  const bonusBHandler = value =>
    dispatch({ type: UPDATE_TEAM_B_BONUS, payload: value })

  const tactAHandler = (variable, value) => {
    if (teamAtacticsVariables.split('&').includes(variable)) {
      var tactAvar0 = teamAtacticsVariables.replace(`${variable}&`, '')
      var tactAval0 = teamAtacticsValues.split('&')
      delete tactAval0[teamAtacticsVariables.split('&').indexOf(variable)]
      tactAval0 = tactAval0.filter(t => t !== undefined).join('&')
      dispatch({
        type: UPDATE_TEAM_A_TACTICS_VARIABLES,
        payload: `${tactAvar0}${variable}&`,
      })
      dispatch({
        type: UPDATE_TEAM_A_TACTICS_VALUES,
        payload: `${tactAval0}${value}&`,
      })
    } else {
      dispatch({
        type: UPDATE_TEAM_A_TACTICS_VARIABLES,
        payload:
          variable === 'no' ? '' : `${teamAtacticsVariables}${variable}&`,
      })
      dispatch({
        type: UPDATE_TEAM_A_TACTICS_VALUES,
        payload: value === 'no' ? '' : `${teamAtacticsValues}${value}&`,
      })
    }
  }

  const tactBHandler = (variable, value) => {
    if (teamBtacticsVariables.split('&').includes(variable)) {
      var tactBvar0 = teamBtacticsVariables.replace(`${variable}&`, '')
      var tactBval0 = teamBtacticsValues.split('&')
      delete tactBval0[teamBtacticsVariables.split('&').indexOf(variable)]
      tactBval0 = tactBval0.filter(t => t !== undefined).join('&')
      dispatch({
        type: UPDATE_TEAM_B_TACTICS_VARIABLES,
        payload: `${tactBvar0}${variable}&`,
      })
      dispatch({
        type: UPDATE_TEAM_B_TACTICS_VALUES,
        payload: `${tactBval0}${value}&`,
      })
    } else {
      dispatch({
        type: UPDATE_TEAM_B_TACTICS_VARIABLES,
        payload:
          variable === 'no' ? '' : `${teamBtacticsVariables}${variable}&`,
      })
      dispatch({
        type: UPDATE_TEAM_B_TACTICS_VALUES,
        payload: value === 'no' ? '' : `${teamBtacticsValues}${value}&`,
      })
    }
  }

  const errorHandler = () => dispatch({ type: UPDATE_ERROR })

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        return Math.min(oldProgress + 100 / Nx, 100)
      })
    }, 6000)

    return () => {
      clearInterval(timer)
    }
  }, [Nx])

  useInterval(checkSimulationOutputs, 5000)

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!error}
        onClose={errorHandler}
        message={error}
        action={
          <>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={errorHandler}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
      <Header MinWidth={MinWidth} />
      &nbsp;
      <Grid container item alignItems='center' style={{ minWidth: MinWidth }}>
        <Grid item xs={4} md={3}>
          <center>
            {mode === 'analysis' && (
              <Link
                to='/dashboard'
                style={{ textDecoration: 'none' }}
                onClick={() => dispatch({ type: RESET_RESULTS })}
              >
                <IconButton>
                  Dashboard &nbsp;
                  <HomeIcon />
                </IconButton>
              </Link>
            )}
          </center>
        </Grid>
        <Grid item xs={4} md={6}>
          <center>
            <h2>
              {(mode === 'demo' && 'Versione DEMO') ||
                description ||
                'Nuova simulazione'}
            </h2>
          </center>
        </Grid>
        <Grid item xs={4} md={3}>
          <center>
            <Link
              to='/'
              style={{ textDecoration: 'none' }}
              onClick={() => {
                dispatch({ type: RESET_GLOBAL })
                dispatch({ type: RESET_DASHBOARD })
                dispatch({ type: RESET_SIMULATION })
                dispatch({ type: RESET_PLAYERS })
              }}
            >
              <IconButton>
                Esci &nbsp;
                <ExitToAppIcon />
              </IconButton>
            </Link>
          </center>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent='center'
        alignItems='stretch'
        style={{ minWidth: MinWidth }}
      >
        <Grid
          container
          item
          xs={3}
          direction='column'
          spacing={5}
          style={{ padding: 10 }}
        >
          <Grid item>
            <Autocomplete
              fullWidth
              options={availableTeams || []}
              value={selectedTeamA || null}
              onChange={(_, value) =>
                dispatch({ type: UPDATE_SELECTED_TEAM_A, payload: value })
              }
              renderInput={params => (
                <TextField {...params} label='Squadra A' />
              )}
            />
          </Grid>
          <LineupSelection spacing={3} autoPlayers={autoPlayersA} />
        </Grid>
        <Grid container item xs={6} direction='column'>
          {status === 'waiting' && mode === 'analysis' && (
            <Grid container item xs alignItems='center'>
              <Grid item xs>
                <center>
                  <h3>La simulazione è in attesa.</h3>
                </center>
              </Grid>
            </Grid>
          )}
          {status === 'running' && mode === 'analysis' && (
            <Grid container item xs alignItems='center'>
              <Grid item xs>
                <center>
                  <h3>La simulazione è in esecuzione...</h3>
                </center>
              </Grid>
            </Grid>
          )}
          {status === 'waiting' && mode !== 'analysis' && (
            <Grid container item xs alignItems='center'>
              <Grid item xs>
                <center>
                  <CircularProgress />
                </center>
              </Grid>
            </Grid>
          )}
          {status === 'running' && mode !== 'analysis' && (
            <Grid container item xs alignItems='center'>
              <Grid item xs>
                <center>
                  {(Nx > 1 && (
                    <LinearProgress variant='determinate' value={progress} />
                  )) || <CircularProgress />}
                </center>
              </Grid>
            </Grid>
          )}
          {status === 'error' && (
            <Grid container item xs alignItems='center' direction='column'>
              <Grid item xs>
                <center>
                  <h2>Errore nella simulazione</h2>
                </center>
              </Grid>
              <Grid item xs>
                <center>
                  <SentimentDissatisfiedIcon fontSize='large' />
                </center>
              </Grid>
            </Grid>
          )}
          {status === 'complete' && !!risultato && !!parziali && (
            <Grid container item xs alignItems='center' direction='column'>
              <Grid item xs>
                <center>
                  <h2>{risultato}</h2>
                </center>
              </Grid>
              <Grid container item xs alignItems='center'>
                <Grid item xs={1} lg={2} />
                {parziali &&
                  parziali.length > 0 &&
                  parziali.map((p, i) => (
                    <Grid item xs lg key={i}>
                      <center>
                        <h4>{p}</h4>
                      </center>
                    </Grid>
                  ))}
                <Grid item xs={1} lg={2} />
              </Grid>
            </Grid>
          )}
          {status === 'complete' && !!tabellino && (
            <Grid container item xs alignItems='center'>
              <Grid item xs>
                <center>
                  <StyledButton
                    buttonwidth={170}
                    buttonheight={50}
                    textbold={0}
                    backgroundcolour={colours.mainLight}
                    hovercolour={colours.main}
                    onClick={handleClickOpenTab}
                  >
                    Tabellino
                  </StyledButton>
                </center>
                <Tabellino
                  tabellino={tabellino}
                  open={openTab}
                  handleClose={handleCloseTab}
                />
              </Grid>
              {Nx === 1 && !!subsLog && (
                <Grid item xs>
                  <center>
                    <StyledButton
                      buttonwidth={170}
                      buttonheight={50}
                      textbold={0}
                      backgroundcolour={colours.mainLight}
                      hovercolour={colours.main}
                      onClick={handleClickOpenLog}
                    >
                      Sostituzioni
                    </StyledButton>
                  </center>
                  <SubsLog
                    subsLog={subsLog}
                    open={openLog}
                    handleClose={handleCloseLog}
                  />
                </Grid>
              )}
            </Grid>
          )}
          {status === 'new' && (
            <Grid container item xs alignItems='center'>
              <Grid item xs={2}>
                <center>
                  <Tooltip title='Azzera formazione A'>
                    <IconButton onClick={clearSelectionsA}>
                      <ClearIcon
                        fontSize='large'
                        style={{ color: colours.red }}
                      />
                    </IconButton>
                  </Tooltip>
                </center>
              </Grid>
              <Grid item lg xs>
                <center>
                  <Tooltip title='Suddivisione dei giocatori'>
                    <ButtonGroup size='large'>
                      <Button
                        variant={divideByRole ? 'contained' : 'text'}
                        onClick={divideByRoleHandler}
                      >
                        Ruoli
                      </Button>
                      <Button
                        variant={!divideByRole ? 'contained' : 'text'}
                        onClick={divideByRoleHandler}
                      >
                        No ruoli
                      </Button>
                    </ButtonGroup>
                  </Tooltip>
                </center>
              </Grid>
              <Grid
                item
                lg={(availableChampionships?.length > 2 && 10) || 4}
                xs={10}
              >
                <center>
                  <Tooltip title='Selezione del campionato'>
                    <ButtonGroup size='large'>
                      {(!!availableChampionships &&
                        availableChampionships.length > 0 &&
                        availableChampionships.map((ch, i) => {
                          return (
                            <Button
                              key={i}
                              variant={
                                selectedChampionship === ch
                                  ? 'contained'
                                  : 'text'
                              }
                              onClick={() => championshipHandler(i)}
                            >
                              {(!!availableChampionshipNames &&
                                availableChampionshipNames.length >= i &&
                                availableChampionshipNames[i]) ||
                                ch}
                            </Button>
                          )
                        })) || (
                        <Button variant='text'>Nessun campionato</Button>
                      )}
                    </ButtonGroup>
                  </Tooltip>
                </center>
              </Grid>
              <Grid item xs={2}>
                <center>
                  <Tooltip title='Azzera formazione B'>
                    <IconButton onClick={clearSelectionsB}>
                      <ClearIcon
                        fontSize='large'
                        style={{ color: colours.red }}
                      />
                    </IconButton>
                  </Tooltip>
                </center>
              </Grid>
            </Grid>
          )}
          {mode === 'challenge' && (
            <Panel
              boxSub1A={boxSub1A}
              boxSub2A={boxSub2A}
              boxSub1B={boxSub1B}
              boxSub2B={boxSub2B}
              boxRot1A={boxRot1A}
              boxRot2A={boxRot2A}
              boxRot1B={boxRot1B}
              boxRot2B={boxRot2B}
              boxTec1A={boxTec1A}
              boxTec2A={boxTec2A}
              boxTec1B={boxTec1B}
              boxTec2B={boxTec2B}
              boxTac1A={boxTac1A}
              boxTac2A={boxTac2A}
              boxTac1B={boxTac1B}
              boxTac2B={boxTac2B}
              boxSub1AHandler={boxSub1AHandler}
              boxSub2AHandler={boxSub2AHandler}
              boxSub1BHandler={boxSub1BHandler}
              boxSub2BHandler={boxSub2BHandler}
              boxRot1AHandler={boxRot1AHandler}
              boxRot2AHandler={boxRot2AHandler}
              boxRot1BHandler={boxRot1BHandler}
              boxRot2BHandler={boxRot2BHandler}
              boxTec1AHandler={boxTec1AHandler}
              boxTec2AHandler={boxTec2AHandler}
              boxTec1BHandler={boxTec1BHandler}
              boxTec2BHandler={boxTec2BHandler}
              boxTac1AHandler={boxTac1AHandler}
              boxTac2AHandler={boxTac2AHandler}
              boxTac1BHandler={boxTac1BHandler}
              boxTac2BHandler={boxTac2BHandler}
              boxCha1A={boxCha1A}
              boxCha2A={boxCha2A}
              boxCha1B={boxCha1B}
              boxCha2B={boxCha2B}
              boxCha1AHandler={boxCha1AHandler}
              boxCha2AHandler={boxCha2AHandler}
              boxCha1BHandler={boxCha1BHandler}
              boxCha2BHandler={boxCha2BHandler}
            />
          )}
          {status === 'new' && (
            <Grid container item xs alignItems='center'>
              <Grid item xs={2}>
                <center>
                  <Tooltip title='Formazione casuale'>
                    <IconButton onClick={randomizeA}>
                      <CasinoIcon
                        fontSize='large'
                        style={{ color: colours.grey }}
                      />
                    </IconButton>
                  </Tooltip>
                </center>
              </Grid>
              <DrawingSelection servesAHandler={servesAHandler} />
              <Grid item xs={2}>
                <center>
                  <Tooltip title='Formazione casuale'>
                    <IconButton onClick={randomizeB}>
                      <CasinoIcon
                        fontSize='large'
                        style={{ color: colours.grey }}
                      />
                    </IconButton>
                  </Tooltip>
                </center>
              </Grid>
            </Grid>
          )}
          <Grid container item xs alignItems='center'>
            <Grid item xs>
              <center>
                <StyledButton
                  buttonwidth={170}
                  buttonheight={50}
                  textbold={0}
                  backgroundcolour={colours.mainLight}
                  hovercolour={colours.main}
                  onClick={handleClickOpenRot}
                >
                  Rotazioni
                </StyledButton>
              </center>
              <Rotations open={openRot} handleClose={handleCloseRot} />
            </Grid>
            <Grid item xs>
              <center>
                <StyledButton
                  buttonwidth={170}
                  buttonheight={50}
                  textbold={0}
                  backgroundcolour={colours.mainLight}
                  hovercolour={colours.main}
                  onClick={handleClickOpenCha}
                >
                  Cambi
                </StyledButton>
              </center>
              <Substitution open={openCha} handleClose={handleCloseCha} />
            </Grid>
            {mode === 'challenge' && (
              <Grid item xs>
                <center>
                  <StyledButton
                    buttonwidth={170}
                    buttonheight={50}
                    textbold={0}
                    backgroundcolour={colours.mainLight}
                    hovercolour={colours.main}
                    onClick={handleClickOpenTec}
                  >
                    Bonus
                  </StyledButton>
                </center>
                <Bonus
                  open={openTec}
                  handleClose={handleCloseTec}
                  bonusAHandler={bonusAHandler}
                  bonusBHandler={bonusBHandler}
                />
              </Grid>
            )}
            <Grid item xs>
              <center>
                <StyledButton
                  buttonwidth={170}
                  buttonheight={50}
                  textbold={0}
                  backgroundcolour={colours.mainLight}
                  hovercolour={colours.main}
                  onClick={handleClickOpenTac}
                >
                  Tattica
                </StyledButton>
              </center>
              <Tactic
                open={openTac}
                handleClose={handleCloseTac}
                tactAHandler={tactAHandler}
                tactBHandler={tactBHandler}
              />
            </Grid>
          </Grid>
          <Grid container item xs alignItems='center'>
            {status === 'new' && (
              <Grid item xs>
                <center>
                  <Link
                    to={mode === 'demo' ? '/simulation' : '/dashboard'}
                    style={{ textDecoration: 'none' }}
                    onClick={
                      isPlayable
                        ? () => SimulateMatch(1, 'Prova rapida')
                        : event => event.preventDefault()
                    }
                  >
                    <StyledButton
                      buttonwidth={200}
                      buttonheight={100}
                      textsize={1.3}
                      textbold={isPlayable ? 1 : 0}
                      borderwidth={isPlayable ? 2 : 1}
                      backgroundcolour={
                        isPlayable ? colours.mainLight : colours.greyLight
                      }
                      hovercolour={colours.main}
                      disabled={!isPlayable}
                    >
                      {(mode === 'analysis' && 'Prova') || 'Gioca'}
                    </StyledButton>
                  </Link>
                </center>
              </Grid>
            )}
            {status === 'new' && mode === 'analysis' && (
              <>
                <Grid item xs={3}>
                  <center>
                    <InputField
                      id='Nx'
                      label='# di simulazioni'
                      value={defaultNx}
                      onChange={i =>
                        dispatch({ type: UPDATE_DEFAULT_NX, payload: i })
                      }
                    />
                  </center>
                </Grid>
                <Grid item xs>
                  <center>
                    <Link
                      to='/dashboard'
                      style={{ textDecoration: 'none' }}
                      onClick={
                        isPlayable
                          ? () => SimulateMatch(defaultNx)
                          : event => event.preventDefault()
                      }
                    >
                      <StyledButton
                        buttonwidth={200}
                        buttonheight={100}
                        textsize={1.3}
                        textbold={isPlayable ? 1 : 0}
                        borderwidth={isPlayable ? 2 : 1}
                        backgroundcolour={
                          isPlayable ? colours.main : colours.greyLight
                        }
                        hovercolour={colours.mainDark}
                        disabled={!isPlayable}
                      >
                        Simula
                      </StyledButton>
                    </Link>
                  </center>
                </Grid>
              </>
            )}
            {['complete', 'error'].includes(status) && mode === 'demo' && (
              <Grid item xs>
                <center>
                  <StyledButton
                    buttonwidth={200}
                    buttonheight={100}
                    textcolour={colours.white}
                    textsize={1.3}
                    textbold={1}
                    borderwidth={2}
                    bordercolour={colours.black}
                    backgroundcolour={colours.black}
                    hovercolour={colours.blackLight}
                    onClick={Reset}
                  >
                    Nuova partita
                  </StyledButton>
                </center>
              </Grid>
            )}
            {['complete', 'error', 'waiting'].includes(status) &&
              mode === 'analysis' && (
                <Grid item xs>
                  <center>
                    <Link
                      to='/dashboard'
                      style={{ textDecoration: 'none' }}
                      onClick={deleteSimulation}
                    >
                      <StyledButton
                        buttonwidth={200}
                        buttonheight={100}
                        textcolour={colours.white}
                        textsize={1.3}
                        textbold={1}
                        borderwidth={2}
                        bordercolour={colours.black}
                        backgroundcolour={colours.black}
                        hovercolour={colours.blackLight}
                      >
                        Elimina
                      </StyledButton>
                    </Link>
                  </center>
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={3}
          direction='column'
          spacing={5}
          style={{ padding: 10 }}
        >
          <Grid item>
            <Autocomplete
              fullWidth
              options={availableTeams || []}
              value={selectedTeamB || null}
              onChange={(_, value) =>
                dispatch({ type: UPDATE_SELECTED_TEAM_B, payload: value })
              }
              renderInput={params => (
                <TextField {...params} label={'Squadra B'} />
              )}
            />
          </Grid>
          <LineupSelection spacing={3} autoPlayers={autoPlayersB} />
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.global.user,
  error: state.global.error,
  mode: state.global.mode,
  defaultNx: state.global.defaultNx,
  availableChampionships: state.global.availableChampionships,
  availableChampionshipNames: state.global.availableChampionshipNames,
  selectedChampionship: state.global.selectedChampionship,
  simulationID: state.simulation.simulationID,
  description: state.simulation.description,
  status: state.simulation.status,
  Nx: state.simulation.Nx,
  divideByRole: state.simulation.divideByRole,
  isPlayable: state.simulation.isPlayable,
  risultato: state.simulation.risultato,
  parziali: state.simulation.parziali,
  tabellino: state.simulation.tabellino,
  subsLog: state.simulation.subsLog,
  servesA: state.simulation.servesA,
  teamArotations: state.simulation.teamArotations,
  teamBrotations: state.simulation.teamBrotations,
  teamAbonus: state.simulation.teamAbonus,
  teamBbonus: state.simulation.teamBbonus,
  teamAtacticsVariables: state.simulation.teamAtacticsVariables,
  teamAtacticsValues: state.simulation.teamAtacticsValues,
  teamBtacticsVariables: state.simulation.teamBtacticsVariables,
  teamBtacticsValues: state.simulation.teamBtacticsValues,
  teamAsubsService: state.simulation.teamAsubsService,
  teamBsubsService: state.simulation.teamBsubsService,
  teamAsubsDiagonal: state.simulation.teamAsubsDiagonal,
  teamBsubsDiagonal: state.simulation.teamBsubsDiagonal,
  availablePlayers: state.players.availablePlayers,
  availableSetters: state.players.availableSetters,
  availableOpposites: state.players.availableOpposites,
  availableMiddles: state.players.availableMiddles,
  availableHitters: state.players.availableHitters,
  availableLiberos: state.players.availableLiberos,
  selectedSetterA: state.players.selectedSetterA,
  selectedOppositeA: state.players.selectedOppositeA,
  selectedMiddle1A: state.players.selectedMiddle1A,
  selectedMiddle2A: state.players.selectedMiddle2A,
  selectedHitter1A: state.players.selectedHitter1A,
  selectedHitter2A: state.players.selectedHitter2A,
  selectedLiberoA: state.players.selectedLiberoA,
  selectedSetterB: state.players.selectedSetterB,
  selectedOppositeB: state.players.selectedOppositeB,
  selectedMiddle1B: state.players.selectedMiddle1B,
  selectedMiddle2B: state.players.selectedMiddle2B,
  selectedHitter1B: state.players.selectedHitter1B,
  selectedHitter2B: state.players.selectedHitter2B,
  selectedLiberoB: state.players.selectedLiberoB,
  availableTeams: state.players.availableTeams,
  selectedTeamA: state.players.selectedTeamA,
  selectedTeamB: state.players.selectedTeamB,
})

const ConnectedSimulation = connect(mapStateToProps)(Simulation)

export default ConnectedSimulation
